import { useRef, useState } from "react";
import Actions from "../components/Actions";
import Icon from "../components/Icon";

function formatNumber(number) {
  return new Intl.NumberFormat("nl-BE").format(number);
}

export default function SelectProject({
  selectedProject,
  setSelectedProject,
  goToNextStep,
  projects,
  goToPreviousStep = null,
  title,
  description,
}) {
  function handleInput(project) {
    setSelectedProject((current) => (current === project ? null : project));
  }
  const form = useRef();

  const done = selectedProject !== null;

  return (
    <form ref={form}>
      <fieldset className="">
        <legend className="question container">
          {title}
          {description.map((d, i) => (
            <span className="question__extra" key={i}>
              {d}
            </span>
          ))}
        </legend>
        <div className="projects container ">
          {projects.map((project, index) => (
            <ProjectCard
              project={project}
              onInput={() => handleInput(project)}
              checked={selectedProject === project}
              key={index}
            />
          ))}
        </div>
      </fieldset>
      <Actions>
        <input
          className="actions__button button"
          type="submit"
          value="Volgende"
          onClick={goToNextStep}
          disabled={!done}
        />
        {goToPreviousStep !== null && (
          <input
            className="actions__button button"
            type="submit"
            value="Vorige"
            onClick={goToPreviousStep}
            disabled={false}
          />
        )}
      </Actions>
    </form>
  );
}

function ProjectCard({ project, onInput, checked }) {
  const id = `project-${project.entryId}`;
  const [expanded, setExpanded] = useState(false);

  function toggleExpansion(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  return (
    <div>
      <input
        type="radio"
        name="interest"
        id={id}
        hidden
        value={project.entryId}
        checked={checked}
        onClick={() => onInput()}
      />
      <label className="project" htmlFor={id} data-expanded={expanded}>
        <div className="project__info">
          {project.image.length > 0 && (
            <img src={project.image} alt="" className="project__img" />
          )}
          <div className="project__text-wrapper">
            <p className="project__title">{project.title}</p>
            <span className="project__budget">
              € {formatNumber(project.budget)}
            </span>
            <button className="project__toggle" onClick={toggleExpansion}>
              meer info
              <Icon name="chevron-down" addlClasses="project__icon" />
            </button>
            <span className="project__category">{project.topic}</span>
          </div>
        </div>
        <p className="project__description">
          <span>
            <span className="project__author">{project.author}</span>
            <span
              dangerouslySetInnerHTML={{ __html: project.description }}
            ></span>
          </span>
        </p>
        <span className="project__button">{checked ? "Gekozen" : "Kies"}</span>
      </label>
    </div>
  );
}
