import { useRef, useState } from "react";
import Actions from "../components/Actions";
import Icon from "../components/Icon";

function formatNumber(number) {
  return new Intl.NumberFormat("nl-BE").format(number);
}

export default function ConfirmChoices({
  projects,
  goToNextStep,
  goToPreviousStep,
}) {
  const form = useRef();

  return (
    <form ref={form}>
      <fieldset className="">
        <legend className="question container">
          Dit zijn jouw 3 keuzes
          <span className="question__extra">
            Klik op &apos;bevestig&apos; om jouw stemmen te geven aan deze drie
            projecten. Klik op &apos;vorige&apos; als je jouw keuzes wil
            veranderen. Ga telkens terug naar de kleine, middelgrote of grote
            projecten om een nieuwe keuze te maken.
          </span>
        </legend>
        <div className="projects container ">
          {projects.map((project, index) => (
            <ProjectCard project={project} key={index} />
          ))}
        </div>
      </fieldset>
      <Actions>
        <input
          className="actions__button button"
          type="submit"
          value="Bevestig"
          onClick={goToNextStep}
        />
        <input
          className="actions__button button"
          type="submit"
          value="Vorige"
          onClick={goToPreviousStep}
        />
      </Actions>
    </form>
  );
}

function ProjectCard({ project }) {
  const id = `project-${project.entryId}`;
  const [expanded, setExpanded] = useState(false);

  function toggleExpansion(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  return (
    <div>
      <label className="project" id={id} data-expanded={expanded}>
        <div className="project__info">
          {project.image.length > 0 && (
            <img src={project.image} alt="" className="project__img" />
          )}
          <div className="project__text-wrapper">
            <p className="project__title">{project.title}</p>
            <span className="project__budget">
              € {formatNumber(project.budget)}
            </span>
            <button className="project__toggle" onClick={toggleExpansion}>
              meer info
              <Icon name="chevron-down" addlClasses="project__icon" />
            </button>
            <span className="project__category">{project.topic}</span>
          </div>
        </div>
        <p className="project__description">
          <span>
            <span className="project__author">{project.author}</span>
            <span
              dangerouslySetInnerHTML={{ __html: project.description }}
            ></span>
          </span>
        </p>
      </label>
    </div>
  );
}
