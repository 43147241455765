import { useEffect, useRef, useState } from "react";
import Steps from "./components/Steps";
import SelectProject from "./pages/select_project";
import Share from "./pages/share";
import Intro from "./pages/Intro";
import projects from "../_data/projects.json";
import APIResponse from "./lib/response";
import ConfirmChoices from "./pages/confirm_projects";
import { CollectClient } from "@tree-company/collect-js-client";
import * as Sentry from "@sentry/browser";

function randomSort(items) {
  const newItems = [...items];
  for (let i = newItems.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newItems[i], newItems[j]] = [newItems[j], newItems[i]];
  }
  return newItems;
}

// We want to sort the themes outside of the react component so this only happens once for each user session
const smallProjects = randomSort(
  projects.filter((p) => p.category === "small")
);
const mediumProjects = randomSort(
  projects.filter((p) => p.category === "medium")
);
const largeProjects = randomSort(
  projects.filter((p) => p.category === "large")
);

const pages = [
  { name: "intro", index: 0 },
  { name: "smallProjects", index: 1 },
  { name: "mediumProjects", index: 2 },
  { name: "largeProjects", index: 3 },
  { name: "confirm", index: 4 },
  { name: "share", index: 5 },
];

// Create a new response
const response = new APIResponse(0);
const client = new CollectClient("https://collect.treecompany.be", {
  id: "88991193e5f301ec542038b7",
  secret: "5O-BbVnX82jCbBLrRgk-vnOH2dF386nEKcbMkVA5wrGA6jdhue4_qnf-yzB0F6GO",
});
const treesponse = client.initTreesponse({ language: "nl" });

export default function App() {
  const [activeStep, setActiveStep] = useState(0);
  const [smallProject, setSmallProject] = useState(null);
  const [mediumProject, setMediumProject] = useState(null);
  const [largeProject, setLargeProject] = useState(null);
  const main = useRef();

  useEffect(() => {
    response.setAnswers({
      smallProject,
      mediumProject,
      largeProject,
    });
    treesponse.setAnswers({
      smallProject,
      mediumProject,
      largeProject,
    });
  }, [smallProject, mediumProject, largeProject]);

  async function goToStep(step) {
    setActiveStep(step);
    treesponse.page = pages[step];
    response.page = step;
    if (step > 4) {
      response.completed = true;
      treesponse.completed = true;
    }
    // If treesponse saving fails, we ignore this and only report
    try {
      treesponse.save();
    } catch (error) {
      Sentry.captureException(error);
    }
    // Await if the response is complete
    response.completed ? await response.save() : response.save();
    main.current.scrollIntoView({ behaviour: "smooth" });
  }

  return (
    <div className="" ref={main}>
      <Steps activeStep={activeStep} setActiveStep={setActiveStep} />
      <main className="">
        {activeStep === 0 && (
          <Intro goToNextStep={() => goToStep(1)} response={response} />
        )}
        {activeStep === 1 && (
          <SelectProject
            projects={smallProjects}
            selectedProject={smallProject}
            setSelectedProject={setSmallProject}
            goToNextStep={() => goToStep(2)}
            title={
              "Kies hier één klein project uit deze budgetcategorie (tot 15.000 euro)"
            }
            description={[
              "In de volgende stappen kan je ook nog voor middelgrote en grote projecten kiezen.",
              "Indien je meer over het project wil weten, klik dan op 'meer info'.",
              "Klik na je keuze op 'volgende' om naar de volgende categorie van projecten te gaan",
            ]}
          />
        )}
        {activeStep === 2 && (
          <SelectProject
            projects={mediumProjects}
            selectedProject={mediumProject}
            setSelectedProject={setMediumProject}
            goToNextStep={() => goToStep(3)}
            goToPreviousStep={() => goToStep(1)}
            title={
              "Kies hier één middelgroot project uit deze budgetcategorie (tot 50.000 euro)"
            }
            description={[
              "In de volgende stap kan je ook nog voor een groot project kiezen.",
              "Indien je meer over het project wil weten, klik dan op 'meer info'.",
              "Klik na je keuze op 'volgende' om naar de volgende categorie van projecten te gaan",
            ]}
          />
        )}
        {activeStep === 3 && (
          <SelectProject
            projects={largeProjects}
            selectedProject={largeProject}
            setSelectedProject={setLargeProject}
            goToNextStep={() => goToStep(4)}
            goToPreviousStep={() => goToStep(2)}
            title={
              "Kies hier één groot project uit deze budgetcategorie (tot 100.000 euro)"
            }
            description={[
              "Indien je meer over het project wil weten, klik dan op 'meer info'.",
              "Klik na je keuze op 'volgende' om jouw keuze voor drie projecten te bevestigen.",
            ]}
          />
        )}
        {activeStep === 4 && (
          <ConfirmChoices
            projects={[smallProject, mediumProject, largeProject]}
            goToNextStep={() => goToStep(5)}
            goToPreviousStep={() => goToStep(3)}
          />
        )}
        {activeStep === 5 && (
          <Share projects={[smallProject, mediumProject, largeProject]} />
        )}
      </main>
    </div>
  );
}
