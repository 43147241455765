import "./css/index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://d9070f0089b5429ebdcbc23d08e50de3@glitchtip.forestry.be/8",
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

document.addEventListener("DOMContentLoaded", () => {
  startShareController();
});

function startShareController() {
  const instances = document.querySelectorAll("[data-controller='share']");
  instances.forEach((el) => new ShareController(el));
}

class ShareController {
  constructor(el) {
    this.el = el;
    el.addEventListener("click", this.shareOnFB);
  }

  get url() {
    return this.el.getAttribute("data-share-url");
  }

  async shareOnFB(event) {
    if (navigator.canShare && navigator.canShare({ url: this.url })) {
      event.preventDefault();
      await navigator.share({
        url: this.url,
      });
    }
  }
}
