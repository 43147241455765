import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore/lite";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

const config = {
  apiKey: "AIzaSyAQW28-ygRSxrcRu2KpIG-hW9MUd4hBFHM",
  authDomain: "asse-jonk.firebaseapp.com",
  projectId: "asse-jonk",
  storageBucket: "asse-jonk.appspot.com",
  messagingSenderId: "169112145435",
  appId: "1:169112145435:web:bdd52e53081bd1b703f2be",
};

class Responder {
  constructor(app) {
    this.userID = localStorage.getItem("fbuid") || null;
    this.auth = getAuth(app);
    // Listen for changes in auth state
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.userID = user.uid;
        localStorage.setItem("fbuid", this.userID);
      } else {
        this.userID = null;
        localStorage.removeItem("fbuid");
      }
    });

    if (this.userID === null) this.startSession();
  }

  async startSession() {
    await signInAnonymously(this.auth);
  }
}

export default class APIResponse {
  #projectName;

  constructor(page, debug = false, projectName = "burgerbudget-genk-2023") {
    this._app = initializeApp(config);
    this._db = getFirestore(this._app);
    this._objectID = null;
    this._responder = new Responder(this._app);
    this._responder.startSession();

    this.language = "NL";
    this.completed = false;
    this.answers = {};
    this.pageHistory = [page];
    this._userAgent = window.navigator.userAgent;
    this._startedAt = new Date();
    this._updatedAt = new Date();
    this.#projectName = projectName;

    // eslint-disable-next-line no-console
    if (debug) console.log("Response: ", this);
  }

  getAnswers() {
    return this.answers;
  }

  getAnswer(key) {
    return this.answers[key];
  }

  setAnswer(key, value) {
    this.answers[key] = value;
  }

  setAnswers(newFields) {
    this.answers = { ...this.answers, ...newFields };
  }

  setCompleted() {
    this.completed = true;
  }

  get firebaseCollection() {
    return `${this.#projectName}_${import.meta.env.MODE}`;
  }

  get page() {
    return this.pageHistory[this.pageHistory.length - 1];
  }

  set page(newPage) {
    this.pageHistory.push(newPage);
  }

  async save() {
    this._updatedAt = new Date();
    // Make sure we have a user id before saving
    if (this._responder.userID === null) await this._responder.startSession();

    this._objectID === null
      ? await this._createRecord()
      : await this._updateRecord();
  }

  async _createRecord() {
    const docRef = await addDoc(collection(this._db, this.firebaseCollection), {
      uid: this._responder.userID,
      userAgent: this._userAgent,
      startedAt: this._startedAt,
      completed: this.completed,
      lastPage: this.page,
      pageHistory: this.pageHistory,
      language: this.language,
      answers: this._transformedAnswers,
      // Update if the survey structure gets big changes
      surveyVersion: "v1",
    });
    this._objectID = docRef.id;
  }

  async _updateRecord() {
    const ref = doc(this._db, this.firebaseCollection, this._objectID);
    await updateDoc(ref, {
      updatedAt: this._updatedAt,
      completed: this.completed,
      lastPage: this.page,
      pageHistory: this.pageHistory,
      answers: this._transformedAnswers,
    });
  }

  get _transformedAnswers() {
    const transformed = {};
    Object.entries(this.answers).forEach(([k, v]) => {
      transformed[k] = Array.isArray(v) ? v.flat() : v;
    });
    return transformed;
  }
}
