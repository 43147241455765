import { useState } from "react";
import Icon from "../components/Icon";
import { formatBudget } from "../lib/number";

export default function Share({ projects }) {
  const shareURL = `${window.location.origin}/delen`;

  const facebookURL = () =>
    `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`;
  const twitterURL = () =>
    `http://twitter.com/intent/tweet?text=${encodeURIComponent(shareURL)}`;
  const whatsappURL = () =>
    `whatsapp://send?text=${encodeURIComponent(shareURL)}`;

  async function shareThroughNavigator(event) {
    if (navigator.canShare && navigator.canShare({ url: shareURL })) {
      event.preventDefault();
      await navigator.share({
        url: shareURL,
      });
    }
  }

  return (
    <div className="">
      <div className="container">
        <h1 className="title">Dit is jouw resultaat</h1>
        <p className="paragraph">
          Bedankt! Je hebt gekozen voor deze 3 projecten. Wil je graag je keuze
          delen met andere Genkenaren? Dat kan! Klik hiervoor op de{" "}
          <span className="share--mobile">Whatsapp, </span>Facebook of Twitter
          knop!
        </p>
        <a
          href={facebookURL()}
          onClick={shareThroughNavigator}
          className="share share--facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={"facebook-f"} addlClasses="icon--xl share__icon" />
        </a>
        <a
          href={twitterURL()}
          className="share"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={"twitter"} addlClasses="icon--xl share__icon" />
        </a>
        <a
          href={whatsappURL()}
          onClick={shareThroughNavigator}
          className="share share--mobile share--whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name={"whatsapp"} addlClasses="icon--xl share__icon" />
        </a>
      </div>
      <div className="projects container">
        {projects.map((project, index) => (
          <ProjectCard project={project} key={index} />
        ))}
      </div>
      <div className="container share-options">
        <a href="https://burgerbudgetgenk.be" className="button">
          Hier vind je alle projectvoorstellen terug!
        </a>
        <a
          href="https://stemming.burgerbudgetgenk.be"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
          className="link link--right"
        >
          Klik hier om met een ander identiteitskaartnummer te stemmen
        </a>
      </div>
    </div>
  );
}

function ProjectCard({ project }) {
  const id = `project-${project.entryId}`;
  const [expanded, setExpanded] = useState(false);

  function toggleExpansion(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  return (
    <div>
      <label className="project" id={id} data-expanded={expanded}>
        <div className="project__info">
          {project.image.length > 0 && (
            <img src={project.image} alt="" className="project__img" />
          )}
          <div className="project__text-wrapper">
            <p className="project__title">{project.title}</p>
            <span className="project__budget">
              {formatBudget(project.budget)}
            </span>
            <button className="project__toggle" onClick={toggleExpansion}>
              meer info
              {/* <Icon name="info-circle" addlClasses="project__icon" /> */}
            </button>
            {/* TODO: Figure out category */}
            {/* <span className="project__category"></span> */}
          </div>
        </div>
        <p className="project__description">
          <span>{project.description}</span>
        </p>
      </label>
    </div>
  );
}
