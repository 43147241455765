import { sha3_512 } from "js-sha3";
import { useState } from "react";
import Actions from "../components/Actions";
import Icon from "../components/Icon";
import { useDebounce } from "../lib/use_debounce";

export default function Intro({ goToNextStep, response }) {
  const [loading, setLoading] = useState(false);
  const [inRange, setInRange] = useState(false);
  const [unique, setUnique] = useState(false);
  const [error, setError] = useState("");
  const [id, setId] = useState("");
  const [controller, setController] = useState(null);

  const checkValidID = useDebounce(async function () {
    setLoading(true);
    const clean = id.split("-").join("");
    const hasBackup = clean.length === 9 && clean[0] !== "0";

    const status = await validateID(
      sha3_512(clean),
      hasBackup ? sha3_512(`000${clean}`) : null
    );
    setInRange(status.inRange);
    setUnique(status.unique);

    if (!status.inRange) {
      setError("Het nummer dat je ingaf is helaas niet geldig.");
    } else if (!status.unique) {
      setError("Je hebt al een keer gestemd voor het burgerbudget");
    } else {
      response.setAnswer("id_nr", status.validHash);
    }

    setLoading(false);
  }, 1000);

  async function validateID(sha1, sha2) {
    const controller = new AbortController();
    setController(controller);
    let url = `https://stemming.burgerbudgetgenk.be/.netlify/functions/validate-rrn?sha512=${sha1}`;
    if (sha2 !== null) url += `&backup=${sha2}`;

    try {
      const response = await fetch(url, { signal: controller.signal });
      const json = await response.json();
      setController(null);
      return json;
    } catch (error) {
      setController(null);
      if (error.message !== "DOMException: The operation was aborted.") {
        throw error;
      }
    }
  }

  function handleInput(e) {
    if (
      e.target.value.length &&
      e.target.value.match(/^[0123456789-]*$/) === null
    ) {
      // Prevent input of an invalid character
      return;
    }
    const value = e.target.value.toUpperCase();
    setId(value);
    // Reset errors and abort previous requests
    setError("");
    controller?.abort();

    /* We don't do anything yet while the user is inputting */
    if (value.length < 6) return;

    checkValidID();
  }

  return (
    <div className="">
      <div className="container">
        <h1 className="title">Welkom bij Burgerbudget Genk</h1>
        <p className="paragraph">
          Vanaf 15 mei tot 31 mei kan je stemmen op één project per
          budgetcategorie, je stemt dus op drie projecten in totaal.
        </p>
      </div>

      <form className="">
        <div className="container input">
          <label htmlFor="rijksregisternummer" className="input__label">
            Vul je identiteitskaartnummer in
          </label>
          <span className="input__helper">
            Na het invullen van het kaartnummer, klik je op &apos;volgende&apos;
          </span>
          <div className="input__field-wrapper">
            <input
              type="text"
              className="input__field"
              onChange={handleInput}
              value={id}
            />
            {loading && <Icon name="circle-notch" addlClasses="input__icon" />}
          </div>
          {error.length > 0 && (
            <span className="input__error">
              <Icon addlClasses="input__error-icon" name="circle-exclamation" />{" "}
              {error}
            </span>
          )}
          <img
            src="/img/specimen-edid-recto.jpeg"
            alt="Voorbeeld van een elektrische identiteitskaart"
            className="input__image"
          />
        </div>

        <div className="container">
          <p className="paragraph">
            Ben je benieuwd naar Burgerbudget Genk
            <br />
            Ontdek het steeds op{" "}
            <a
              className="link"
              href="https://burgerbudgetgenk.be"
              target="_blank"
              rel="noreferrer noopener"
            >
              burgerbudgetgenk.be
            </a>
          </p>
        </div>
        <Actions>
          <input
            type="submit"
            value="Volgende"
            className="actions__button button"
            onClick={goToNextStep}
            disabled={!inRange || !unique}
          />
        </Actions>
      </form>
    </div>
  );
}
