export default function Steps({ activeStep }) {
  const steps = [
    "Identiteitskaartnummer",
    "Kleine projecten",
    "Middelgrote projecten",
    "Grote projecten",
    "Bevestig mijn keuzes",
    "Deel mijn keuzes",
  ];

  return (
    <ul className="steps">
      {steps.map((step, i) => {
        let classes = ["step"];
        if (i < activeStep) classes.push("step--active");
        else if (i === activeStep) classes.push("step--current");
        return (
          <li className={classes.join(" ")} key={i}>
            <span className="step__counter">{i + 1}</span>
            {step}
          </li>
        );
      })}
    </ul>
  );
}
