import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef } from "react";

export function useDebounce(cb, delay) {
  const options = { leading: false, trailing: true };
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  });
  return useCallback(
    debounce((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}
